import React, {FC, useContext} from 'react';
import {graphql, PageProps} from 'gatsby';
import {ContentfulPageHome, ContentfulPageKontakt} from '../model/generated/graphql';

import {PageTransition} from '../components/PageTransition/PageTransition';
import {ContactSection} from '../components/ContactSection';

import {PageContentWrapper} from '../components/PageContentWrapper/PageContentWrapper';
import {Container} from '../components/Container/Container';
import {
    ContentfulRichTextGatsbyReference,
    renderRichText,
    RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import {SectionCard, SectionImg, SectionTitle, TeleporadaContent, TeleporadaSection} from './index';
import Seo from '../components/Seo/Seo';
import LocaleContext from '../context/Locale';

interface ContactQueryProps {
    contentfulPageKontakt: ContentfulPageKontakt;
    contentfulPageHome: ContentfulPageHome;
}
type ContactPageProps = PageProps<ContactQueryProps>;

const ContactPage: FC<ContactPageProps> = ({ data, location }) => {
    const { activeLocale } = useContext(LocaleContext);
    return (
        <>
            <Seo locale={activeLocale} seo={data.contentfulPageKontakt.seo} />
            <PageContentWrapper title={data.contentfulPageKontakt.title}>
                <PageTransition>
                    <ContactSection data={data.contentfulPageKontakt} hideTitle={true} shouldRenderMap={true} />

                    <TeleporadaSection page={'kontakt'}>
                        <Container>
                            <TeleporadaContent>
                                <SectionCard>
                                    <div>
                                        <SectionTitle>{data.contentfulPageHome.teleporadaTytu}</SectionTitle>

                                        {renderRichText(
                                            data.contentfulPageHome
                                                .teleporadaOpis as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                        )}
                                    </div>
                                </SectionCard>
                                <SectionImg image={data.contentfulPageHome.teleporadaZdjcie.gatsbyImageData} alt="" />
                            </TeleporadaContent>
                        </Container>
                    </TeleporadaSection>

                    {/*<div style={{ marginTop: 100 }}>*/}
                    {/*    <Container>*/}
                    {/*        <AdBanner>*/}
                    {/*            <AdBannerTitle*/}
                    {/*                href="https://booksy.com/pl-pl/158309_mag-med-rehabilitacja-fizjoterapia_fizjoterapia_23280_lodz"*/}
                    {/*                target={'_blank'}*/}
                    {/*            >*/}
                    {/*                Umów wizytę na <Booksy width={160} fill="white" style={{ marginLeft: 16 }} />*/}
                    {/*            </AdBannerTitle>*/}
                    {/*        </AdBanner>*/}
                    {/*    </Container>*/}
                    {/*</div>*/}
                </PageTransition>
            </PageContentWrapper>
        </>
    );
};

export default ContactPage;

export const pageQuery = graphql`
    query ContactQuery($node_locale: String) {
        contentfulPageKontakt(contentful_id: { eq: "4CI60KPsDdc3LGC7S3xH9k" }, node_locale: { eq: $node_locale }) {
            ...contact
        }
        contentfulPageHome(contentful_id: { eq: "2RUjuj71vLtS3N3TlqZcv4" }, node_locale: { eq: $node_locale }) {
            teleporadaTytu
            teleporadaZdjcie {
                contentful_id
                gatsbyImageData(layout: CONSTRAINED, quality: 90, height: 400)
            }
            teleporadaOpis {
                raw
            }
        }
    }
`;
